<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <validation-observer ref="observer">
        <form ref="form" class="container pa-0">
          <div v-if="value.fornecedor">
            <v-row class="d-flex justify-between align-center">
              <v-col col="3"> Fornecedor </v-col>
              <v-col col="9">
                <v-row>
                  <v-col col="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="fornecedor_avancar"
                    >
                      <v-autocomplete
                        v-model="value.grupos.avancar.fornecedor"
                        :items="grupos.fornecedor"
                        item-text="nome"
                        item-value="public_id"
                        :error-messages="errors"
                        label="Grupos de Avanço"
                        prepend-icon="mdi-account-group"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        hint="Selecione os grupos do fornecedor que pode avançar."
                        persistent-hint
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="fornecedor_recuar"
                    >
                      <v-autocomplete
                        v-model="value.grupos.recuar.fornecedor"
                        :items="grupos.fornecedor"
                        item-text="nome"
                        item-value="public_id"
                        :error-messages="errors"
                        label="Grupos de Recuo"
                        prepend-icon="mdi-account-group"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        hint="Selecione os grupos do fornecedor que pode recuar."
                        persistent-hint
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
          <div v-if="value.terminal">
            <v-row class="d-flex justify-between align-center">
              <v-col col="3"> Terminal </v-col>
              <v-col col="9">
                <v-row>
                  <v-col col="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="terminal_avancar"
                      v-if="value.terminal"
                    >
                      <v-autocomplete
                        v-model="value.grupos.avancar.terminal"
                        :items="grupos.terminal"
                        item-text="nome"
                        item-value="public_id"
                        :error-messages="errors"
                        label="Grupos de Avanço"
                        prepend-icon="mdi-account-group"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        hint="Selecione os grupos do terminal que pode avançar."
                        persistent-hint
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="terminal_recuar"
                      v-if="value.terminal"
                    >
                      <v-autocomplete
                        v-model="value.grupos.recuar.terminal"
                        :items="grupos.terminal"
                        item-text="nome"
                        item-value="public_id"
                        :error-messages="errors"
                        label="Grupos de Recuo"
                        prepend-icon="mdi-account-group"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        hint="Selecione os grupos do terminal que pode recuar."
                        persistent-hint
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row class="d-flex justify-between align-center">
              <v-col col="3"> Unidade </v-col>
              <v-col col="9">
                <v-row>
                  <v-col col="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="unidade_avancar"
                    >
                      <v-autocomplete
                        v-model="value.grupos.avancar.unidade"
                        :items="grupos.unidade"
                        item-text="nome"
                        item-value="public_id"
                        :error-messages="errors"
                        label="Grupos de Avanço"
                        prepend-icon="mdi-account-group"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        hint="Selecione os grupos da unidade que pode avançar."
                        persistent-hint
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="unidade_recuar"
                    >
                      <v-autocomplete
                        v-model="value.grupos.recuar.unidade"
                        :items="grupos.unidade"
                        item-text="nome"
                        item-value="public_id"
                        :error-messages="errors"
                        label="Grupos de Recuo"
                        prepend-icon="mdi-account-group"
                        chips
                        clearable
                        deletable-chips
                        multiple
                        hint="Selecione os grupos da unidade que pode recuar."
                        persistent-hint
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </form>
      </validation-observer>
    </template>
    <template v-slot:buttons>
      <buttons-cadastro @close="close" @save="save" />
    </template>
  </base-modal>
</template>
<script>
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'
import GrupoApi from '@/services/gerencial/grupo'
import { mapState } from 'vuex'
export default {
  components: { BaseModal, ButtonsCadastro },
  name: 'CadastroServico',
  etapas: [],
  props: {
    formTitle: String,
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      grupos: {
        fornecedor: [],
        terminal: [],
        unidade: [],
      },
      formError: false,
      dialogEtapa: false,
      defaultItem: {
        public_id: null,
        id: null,
        fornecedor: null,
        terminal: null,
        etapa_servico: {
          nome: null,
        },
        ordem: null,
        grupos: {
          avanco: {
            fornecedor: [],
            terminal: [],
            unidade: [],
          },
          recuo: {
            fornecedor: [],
            terminal: [],
            unidade: [],
          },
        },
      },
    }
  },
  async created() {
    this.grupos.unidade = await this.getGrupos(this.empresaAtual)
    if (this.value.fornecedor) {
      this.grupos.fornecedor = await this.getGrupos({
        public_id: this.value.fornecedor.fornecedor_id,
      })
    }
    if (this.value.terminal) {
      this.grupos.terminal = await this.getGrupos({
        public_id: this.value.terminal.terminal_id,
      })
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    async getGrupos(empresa) {
      try {
        const grupos = await GrupoApi.getGrupos(empresa)
        return Promise.resolve(grupos)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async validate() {
      await this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    close() {
      // Object.assign(this.value, this.defaultItem)
      this.$refs.observer.reset()
      this.$emit('close')
    },

    save() {
      this.$emit('save', this.value)

      this.$refs.observer.reset()
    },
  },
}
</script>
<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{attrs:{"formTitle":_vm.formTitle},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validation-observer',{ref:"observer"},[_c('form',{ref:"form",staticClass:"container pa-0"},[(_vm.value.fornecedor)?_c('div',[_c('v-row',{staticClass:"d-flex justify-between align-center"},[_c('v-col',{attrs:{"col":"3"}},[_vm._v(" Fornecedor ")]),_c('v-col',{attrs:{"col":"9"}},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"fornecedor_avancar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.grupos.fornecedor,"item-text":"nome","item-value":"public_id","error-messages":errors,"label":"Grupos de Avanço","prepend-icon":"mdi-account-group","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os grupos do fornecedor que pode avançar.","persistent-hint":""},model:{value:(_vm.value.grupos.avancar.fornecedor),callback:function ($$v) {_vm.$set(_vm.value.grupos.avancar, "fornecedor", $$v)},expression:"value.grupos.avancar.fornecedor"}})]}}],null,false,964808129)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"fornecedor_recuar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.grupos.fornecedor,"item-text":"nome","item-value":"public_id","error-messages":errors,"label":"Grupos de Recuo","prepend-icon":"mdi-account-group","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os grupos do fornecedor que pode recuar.","persistent-hint":""},model:{value:(_vm.value.grupos.recuar.fornecedor),callback:function ($$v) {_vm.$set(_vm.value.grupos.recuar, "fornecedor", $$v)},expression:"value.grupos.recuar.fornecedor"}})]}}],null,false,3832290427)})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1)],1):_vm._e(),(_vm.value.terminal)?_c('div',[_c('v-row',{staticClass:"d-flex justify-between align-center"},[_c('v-col',{attrs:{"col":"3"}},[_vm._v(" Terminal ")]),_c('v-col',{attrs:{"col":"9"}},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[(_vm.value.terminal)?_c('validation-provider',{attrs:{"name":"terminal_avancar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.grupos.terminal,"item-text":"nome","item-value":"public_id","error-messages":errors,"label":"Grupos de Avanço","prepend-icon":"mdi-account-group","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os grupos do terminal que pode avançar.","persistent-hint":""},model:{value:(_vm.value.grupos.avancar.terminal),callback:function ($$v) {_vm.$set(_vm.value.grupos.avancar, "terminal", $$v)},expression:"value.grupos.avancar.terminal"}})]}}],null,false,4232031658)}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[(_vm.value.terminal)?_c('validation-provider',{attrs:{"name":"terminal_recuar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.grupos.terminal,"item-text":"nome","item-value":"public_id","error-messages":errors,"label":"Grupos de Recuo","prepend-icon":"mdi-account-group","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os grupos do terminal que pode recuar.","persistent-hint":""},model:{value:(_vm.value.grupos.recuar.terminal),callback:function ($$v) {_vm.$set(_vm.value.grupos.recuar, "terminal", $$v)},expression:"value.grupos.recuar.terminal"}})]}}],null,false,3344410928)}):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1)],1):_vm._e(),_c('div',[_c('v-row',{staticClass:"d-flex justify-between align-center"},[_c('v-col',{attrs:{"col":"3"}},[_vm._v(" Unidade ")]),_c('v-col',{attrs:{"col":"9"}},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"unidade_avancar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.grupos.unidade,"item-text":"nome","item-value":"public_id","error-messages":errors,"label":"Grupos de Avanço","prepend-icon":"mdi-account-group","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os grupos da unidade que pode avançar.","persistent-hint":""},model:{value:(_vm.value.grupos.avancar.unidade),callback:function ($$v) {_vm.$set(_vm.value.grupos.avancar, "unidade", $$v)},expression:"value.grupos.avancar.unidade"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('validation-provider',{attrs:{"name":"unidade_recuar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.grupos.unidade,"item-text":"nome","item-value":"public_id","error-messages":errors,"label":"Grupos de Recuo","prepend-icon":"mdi-account-group","chips":"","clearable":"","deletable-chips":"","multiple":"","hint":"Selecione os grupos da unidade que pode recuar.","persistent-hint":""},model:{value:(_vm.value.grupos.recuar.unidade),callback:function ($$v) {_vm.$set(_vm.value.grupos.recuar, "unidade", $$v)},expression:"value.grupos.recuar.unidade"}})]}}])})],1)],1)],1)],1)],1)])])]},proxy:true},{key:"buttons",fn:function(){return [_c('buttons-cadastro',{on:{"close":_vm.close,"save":_vm.save}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard :heading="title">
          <v-row class="px-5">
            <v-col col="5">
              <v-autocomplete
                v-model="pesquisa.fornecedor"
                :items="fornecedores"
                :item-text="
                  (item) => item.business_name + ` - ${item.cnpj || item.cpf}`
                "
                item-value="public_id"
                return-object
                @change="setTerminais()"
                label="Fornecedor"
              />
            </v-col>
            <v-col col="5">
              <v-autocomplete
                v-model="pesquisa.terminal"
                :items="
                  terminais.filter((item) =>
                    servico.etapas.find(
                      (etapa) =>
                        etapa.terminal &&
                        etapa.terminal.public_id == item.public_id
                    )
                  )
                "
                item-text="business_name"
                item-value="public_id"
                @change="pesquisar()"
                label="Terminal"
              />
            </v-col>
            <v-col col="2">
              <v-btn color="primary" dark class="mt-2" @click="resetar"
                >Resetar</v-btn
              >
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div
            class="py-5"
            v-show="ordem_etapa.fornecedor || ordem_etapa.terminal"
          >
            <v-row>
              <v-col col="4" align="center">
                <div class="list-etapas">
                  <label for="etapas">Unidade:</label>
                  <div class="py-2 mt-2 etapa__header">
                    <p>Posição</p>
                    <p>Nome</p>
                    <p>Ação</p>
                  </div>
                  <div
                    v-for="(etapa, index) in servico.etapas.filter(
                      (etapa) =>
                        etapa.fornecedor == null && etapa.terminal == null
                    )"
                    :key="index"
                  >
                    <div class="py-2 etapa">
                      <p>{{ index + 1 }}</p>
                      <p>{{ etapa.nome }}</p>
                      <p
                        class="d-flex justify-center line-data__icons"
                        @click="adicionarOrdemEtapa(etapa)"
                      >
                        <v-icon> mdi-chevron-right </v-icon>
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col col="4" align="center">
                <div class="list-etapas">
                  <label for="etapas">Fornecedor:</label>
                  <div class="py-2 mt-2 etapa__header">
                    <p>Posição</p>
                    <p>Nome</p>
                  </div>
                  <div
                    v-for="(etapa, index) in servico.etapas
                      .filter(
                        (etapa) =>
                          etapa.fornecedor !== null && etapa.terminal == null
                      )
                      .filter(
                        (etapa) =>
                          etapa.fornecedor.public_id ==
                          (ordem_etapa.fornecedor
                            ? ordem_etapa.fornecedor.public_id
                            : null)
                      )"
                    :key="index"
                  >
                    <div class="py-2 etapa">
                      <p>{{ index + 1 }}</p>
                      <p>{{ etapa.nome }}</p>
                      <p
                        class="d-flex justify-center line-data__icons"
                        @click="adicionarOrdemEtapa(etapa)"
                      >
                        <v-icon> mdi-chevron-right </v-icon>
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col col="4" align="center" v-show="ordem_etapa.terminal">
                <div class="list-etapas">
                  <label for="etapas">Terminal:</label>
                  <div class="py-2 mt-2 etapa__header">
                    <p>Posição</p>
                    <p>Nome</p>
                  </div>
                  <div
                    v-for="(etapa, index) in servico.etapas
                      .filter(
                        (etapa) =>
                          etapa.fornecedor == null && etapa.terminal !== null
                      )
                      .filter(
                        (etapa) =>
                          etapa.terminal.public_id == ordem_etapa.terminal
                      )"
                    :key="index"
                  >
                    <div class="py-2 etapa">
                      <p>{{ index + 1 }}</p>
                      <p>{{ etapa.nome }}</p>
                      <p
                        class="d-flex justify-center line-data__icons"
                        @click="adicionarOrdemEtapa(etapa)"
                      >
                        <v-icon> mdi-chevron-right </v-icon>
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12">
                <v-divider class="m-5"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="list-etapas">
                <div class="py-2 mt-2 etapa__header five">
                  <p>Posição</p>
                  <p>Nome</p>
                  <p>Fornecedor</p>
                  <p>Terminal</p>
                  <p>Ações</p>
                </div>
                <draggable v-model="ordem_etapas" @change="changeOrdemEtapa">
                  <div
                    v-for="(ordem_etapa, index) in ordem_etapas"
                    :key="index"
                  >
                    <div class="py-2 etapa five">
                      <p>{{ index + 1 }}</p>
                      <p>{{ ordem_etapa.etapa_servico.nome }}</p>
                      <p>
                        {{
                          ordem_etapa.etapa_servico.fornecedor
                            ? ordem_etapa.etapa_servico.fornecedor.trade_name
                            : '--'
                        }}
                      </p>
                      <p>
                        {{
                          ordem_etapa.etapa_servico.terminal
                            ? ordem_etapa.etapa_servico.terminal.trade_name
                            : '--'
                        }}
                      </p>
                      <div>
                        <v-icon @click="editar(ordem_etapa)" small
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          @click="removeOrdemEtapa(ordem_etapa)"
                          small
                          color="red"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </div>
                  </div>
                </draggable>
              </v-col>
            </v-row>
          </div>
          <v-dialog v-model="dialog" max-width="650px">
            <EditarOrdemEtapa
              v-if="dialog"
              :formTitle="formTitle"
              ref="editarOrdemEtapa"
              v-model="formOrdemEtapa"
              @close="close"
              @save="save"
            ></EditarOrdemEtapa>
          </v-dialog>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import contratoAPI from '@/services/tenants/contratos'

import Perfil from '@/utils/perfil'

import { mapState, mapActions } from 'vuex'

import draggable from 'vuedraggable'
import EditarOrdemEtapa from '@/components/financeiro/servico/ordem_etapa/EditarOrdemEtapa.vue'

export default {
  name: 'OrdemEtapa',
  components: { draggable, EditarOrdemEtapa },
  data() {
    return {
      headers: {},
      dialog: false,
      formTitle: 'Editar Ordem Etapa',
      formOrdemEtapa: {},
      terminais: [],
      servico: {},
      pesquisa: {
        fornecedor: null,
        terminal: null,
      },
      ordem_etapas: [],
      ordem_etapa: {
        fornecedor: null,
        terminal: null,
        etapa_servico: null,
        ordem: null,
        grupos: {
          fornecedor: [],
          terminal: [],
          unidade: [],
        },
      },
      page: {
        title: 'Gerenciamento de serviços',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Serviços',
          disabled: false,
        },
        {
          text: 'Ordem',
          disabled: false,
        },
      ],
    }
  },
  async created() {
    if (
      this.$route.params.servico == undefined ||
      this.$route.params.servico == 0
    ) {
      this.$router.push({
        name: 'servicos',
      })
    } else {
      this.servico = this.$route.params.servico
      this.ordem_etapa.servico = this.$route.params.servico
    }
    await this.getFornecedores()
  },
  computed: {
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('financeiro', ['ordemEtapas']),
    title() {
      return 'Ordem das Etapas - ' + this.servico.descricao
    },
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('fornecedores', ['getFornecedores']),
    ...mapActions('financeiro', [
      'getOrdemEtapas',
      'addOrdemEtapa',
      'updateOrdemEtapa',
      'deleteOrdemEtapa',
    ]),
    async save(form) {
      const payload = {
        public_id: form.public_id,
        fornecedor: form.fornecedor ? form.fornecedor.public_id : null,
        terminal: form.terminal ? form.terminal.public_id : null,
        etapa_servico: form.etapa_servico.public_id,
        ordem: form.ordem,
        servico: form.servico,
        grupos: form.grupos,
      }
      await this.updateOrdemEtapa(payload)
      this.ordem_etapas = this.ordem_etapas.map((etapa, index) => {
        return {
          ...etapa,
          ordem: index,
        }
      })
      this.close()
    },
    async editar(ordem_etapa) {
      this.formTitle = `Editar Ordem Etapa - ${
        ordem_etapa.etapa_servico.nome
      } (${ordem_etapa.ordem + 1})`
      this.formOrdemEtapa = ordem_etapa
      this.dialog = true
    },
    async close() {
      this.dialog = false
    },
    async adicionarOrdemEtapa(etapa) {
      const ordem_etapa = {
        ...this.ordem_etapa,
        etapa_servico: etapa,
      }
      if (
        this.ordem_etapas.findIndex(
          (item) =>
            item.etapa_servico.public_id ==
              ordem_etapa.etapa_servico.public_id &&
            item.fornecedor?.public_id == ordem_etapa.fornecedor?.public_id &&
            item.terminal?.public_id == ordem_etapa.terminal
        ) == -1
      ) {
        const payload = {
          fornecedor: ordem_etapa.fornecedor
            ? ordem_etapa.fornecedor.public_id
            : null,
          terminal: ordem_etapa.terminal,
          etapa_servico: ordem_etapa.etapa_servico.public_id,
          ordem: this.ordem_etapas.length,
          servico: this.servico,
        }
        const _ordem_etapa = await this.addOrdemEtapa(payload)
        this.ordem_etapas.push(_ordem_etapa)
      }
    },
    async changeOrdemEtapa({ moved: { newIndex } }) {
      const payload = {
        public_id: this.ordem_etapas[newIndex].public_id,
        fornecedor: this.ordem_etapas[newIndex].fornecedor
          ? this.ordem_etapas[newIndex].fornecedor.public_id
          : null,
        terminal: this.ordem_etapas[newIndex].terminal
          ? this.ordem_etapas[newIndex].terminal.public_id
          : null,
        etapa_servico: this.ordem_etapas[newIndex].etapa_servico.public_id,
        ordem: newIndex,
        servico: this.servico,
        grupos: this.ordem_etapas[newIndex].grupos,
      }
      await this.updateOrdemEtapa(payload)
      this.ordem_etapas = this.ordem_etapas.map((etapa, index) => {
        return {
          ...etapa,
          ordem: index,
        }
      })
    },

    async setTerminais() {
      this.terminais = []
      this.pesquisa.terminal = null
      this.pesquisar()
      try {
        this.terminais = await contratoAPI.getContratos(
          this.pesquisa.fornecedor.fornecedor_id,
          {
            perfil: Perfil.Terminal,
          }
        )
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    resetar() {
      this.terminais = []
      this.ordem_etapa.fornecedor = null
      this.ordem_etapa.terminal = null
      this.pesquisa.terminal = null
      this.pesquisa.fornecedor = null
    },
    async pesquisar() {
      this.ordem_etapa.fornecedor = null
      this.ordem_etapa.terminal = null
      this.ordem_etapa.terminal = this.pesquisa.terminal
      this.ordem_etapa.fornecedor = this.pesquisa.fornecedor
      this.ordem_etapas = []
      await this.getOrdemEtapas({
        servico: this.servico,
        fornecedor: this.ordem_etapa.fornecedor
          ? this.ordem_etapa.fornecedor.fornecedor_id
          : null,
        terminal: this.ordem_etapa.terminal,
        sem_terminal: this.ordem_etapa.terminal ? null : true,
      })
      this.ordem_etapas = [...this.ordemEtapas]
    },
    async removeOrdemEtapa(ordem_etapa) {
      await this.deleteOrdemEtapa(ordem_etapa)
      const index = this.ordem_etapas
        .map((item) => item.public_id)
        .indexOf(ordem_etapa.public_id)
      this.ordem_etapas.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
.list-etapas {
  padding: auto;
  max-height: 400px;
  overflow-y: scroll;

  .etapa__header,
  .etapa {
    /* border-top: 1px solid; */
    border: 1px solid #949494;
    border-top: none;
    padding: 0 12px;
    display: grid;
    grid-template-columns: 2fr 7fr 1fr;
    text-transform: capitalize;
    p {
      margin: 0;
    }
  }

  .etapa__header.five,
  .etapa.five {
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  }

  .etapa {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .etapa:hover {
    background: #eee;
  }

  .etapa__header,
  .etapa {
    color: rgb(70 70 70);
  }

  .etapa__header p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    font-weight: 700;
  }

  .etapa__header,
  > div:first-child {
    border-top: 1px solid #949494;
  }
}
</style>

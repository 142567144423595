<template>
  <fragment>
    <v-btn color="black darken-1" text @click="close">Cancelar</v-btn>
    <v-btn
      type="button"
      :class="styles ? styles : 'button-submit-green'"
      :disabled="disabled"
      @click="save"
      >{{ value }}</v-btn
    >
  </fragment>
</template>

<script>
export default {
  name: 'ButtonsCadastro',
  props: {
    value: { type: String, default: 'Salvar' },
    styles: String,
    disabled: Boolean,
  },

  methods: {
    close() {
      this.$emit('close')
    },

    save() {
      this.$emit('save')
    },
  },
}
</script>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
</style>